export interface ILookup {
  id: number
  name: string
  description?: string | undefined
  externalCode?: string | undefined
  value?: string | undefined
}

export class Lookup implements ILookup {
  id: number;
  name: string;
  description?: string | undefined = "";
  externalCode?: string | undefined = "";
  value?: string | undefined = "";    
}