import React, { Component, useEffect, useState } from 'react';
import "./SideMenu.scss";
import authService from '../../api-authorization/AuthorizeService';
import clubService from '../../services/club/Club';
import { IClub } from '../../interfaces/IClub';
import { Loader } from '@progress/kendo-react-indicators';
import { MemberLookup } from '../../components/memberLookup/MemberLookup';
import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';


interface IState {
  user_name: string | null
  clubs: any;
  visible: boolean;
  logo: string;  
  taskListCount: number;  
}

interface IProps {
  children?: React.ReactNode | undefined;  
}

declare global {
  interface Window {
      Intercom: any;
  }
}

export function SideMenu(props: IProps) { 
  
  let load_retry_count: number = 0;

  const [state, setState] = useState<IState>();
  const [loading, setLoading] = useState<boolean>();
  const [responsiveMenuExpanded, setResponsiveMenuExpanded] = useState<boolean>();

  let history = useHistory();  

  useEffect(() => {

    setLoading(true);
    fetchData();

  }, []);

  const fetchData = () => {
    
    clubService.getSidebarConfig().then((sb) => {
      
      return sb;

    }).then((sb) => {

      getUserInitials().then((ui) => {

        setState({
          logo: sb.clubLogo,
          clubs: sb.clubs,
          taskListCount: sb.taskListCount,
          user_name: ui,        
          visible: true,          
        });

        setLoading(false);
        return ui;
      });
    }).catch((r) => { 
          
      load_retry_count++;
      if(load_retry_count < 5) {
        window.setTimeout(() => { fetchData() }, 800);
      } 
      else {
        window.location.pathname = "/authentication/login";
      }      
    });
  }  

  const getUserInitials = async(): Promise<string> => {
    
    let initial = "";
    const user = await authService.getUser();
    if (user) {      
      initial = user.name[0];      
    }

    return initial;
  }

  const onMemberSelected = (e : any) => {
    //window.location.pathname = `/clubadmin/club/member/view/${e.id}`;
    history.push(`/clubadmin/club/member/view/${e.id}`);
  }

  const onUserSelected = () => {
    window.location.href = "/Identity/Account/Manage/";
  }

  const onSelectResponsiveMenuTrigger = () => {  
    setResponsiveMenuExpanded(true);
  }

  const onSelectCloseResponsiveMenu = () => {  
    setResponsiveMenuExpanded(false);
  }

  
  return (
    <div className={"menu-wrapper " + (responsiveMenuExpanded ? 'responsive-menu-expanded' : '')}>
      {!state?.visible && loading &&
      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} className="loading-spinner">
        <Loader size="medium" type={'infinite-spinner'} themeColor='error' />
      </div> 
      }
      {!responsiveMenuExpanded &&
      <div className="responsive-menu-trigger">
        <div className="icon icon-40 burger-menu" onClick={() => onSelectResponsiveMenuTrigger()}></div>
      </div>
      }
      {responsiveMenuExpanded &&
      <div className="responsive-menu-trigger">
        <div className="icon icon-25 cross" onClick={() => onSelectCloseResponsiveMenu()}></div>
      </div>
      }
      {state?.visible && !loading &&
        <div className="align-items-start justify-content-between flex-column h-100 w-100 inner-content">
          <div className="w-100">
            <div className='d-flex align-items-center justify-content-between px-3 pt-3 mb-4'>
              {state.logo.length > 0 &&
              <div
                className='client-logo' 
                style={{
                  backgroundImage:`url(${state.logo})`, 
                }}>
              </div>
              }
              {state.logo.length == 0 &&
              <div
                className='client-logo simple-logo'>
              </div>
              }
              <div
                onClick={() => onUserSelected()}
                className='d-flex align-items-center justify-content-center text-uppercase'
                style={{backgroundColor: '#B91C1C', borderRadius: 100, height: 30, width: 30, color: 'white', fontSize: 14}}>
                  <p className='m-0 p-0' style={{top: 1, position: 'relative'}}>
                    {state.user_name}
                  </p>
              </div>
            </div>
            <div className="px-3 member-lookup mb-4">
              <div className="icon icon-20 me-2 sidebar-search"></div>
              <MemberLookup clubId={0} onSelected={(e) => onMemberSelected(e)} defaultValue={''}/>   
            </div>
            <div>
              <div
                onClick={() => history.push('/')} 
                className={"menu-item flex-row d-flex items-center py-2 px-3 " + (window.location.pathname == '/' ? 'selected' : '')}
                id="dashboard">
                <div className="icon icon-20 me-2 new-dashboard"></div>
                <p className="m-0 p-0"> 
                  Dashboard
                </p>
              </div>
              <div
                onClick={() => history.push(`/tasks/member-task-list/0`)} 
                id="my-task-list"
                className={"menu-item flex-row d-flex items-center justify-content-between py-2 px-3 " + (window.location.pathname.includes('/tasks/member-task-list/') ? 'selected' : '')}>
                <div className="d-flex items-center">
                  <div className="icon icon-20 me-2 my-tasks"></div>
                  <p className="m-0 p-0"> 
                    My Task List
                  </p>
                </div>
                {state.taskListCount > 0 &&
                <div
                  className='d-flex align-items-center justify-content-center' 
                  style={{color: 'white', backgroundColor: "#B91C1C", padding: '0 5px', height:20, borderRadius: 20, fontSize: 10}}>
                  {state.taskListCount}
                </div>
                }
              </div>
              <div
                onClick={() => history.push('/member/search')}  
                id="all-members"
                className={"menu-item flex-row d-flex items-center py-2 px-3 " + (window.location.pathname.includes('/member/search') ? 'selected' : '')}>
                <div className="icon icon-20 me-2 member-search"></div>
                <p className="m-0 p-0"> 
                  All Members
                </p>
              </div>
              <div className="divider"></div>
              <div className="menu-item-title ms-3 d-flex flex-column" id="your-clubs">
                <div className="flex-row d-flex items-center mb-2">
                  <div className="icon icon-20 me-2 your-clubs"></div>
                  <p className="m-0 p-0"> 
                    Your Clubs
                  </p>
                </div>
                {state.clubs.map((club : IClub) => {
                  return (
                    <div
                      onClick={() => history.push(`/clubadmin/${club.id}/club-schedule`)}  
                      className={"menu-item py-2 px-3 " + (window.location.pathname.includes(`/clubadmin/${club.id}`) ? 'selected' : '')}
                      key={club.id}>
                      <p className="m-0 p-0" style={{color: 'black', fontSize: 13}}>
                        {club.name}
                      </p>
                    </div>
                  )
                })
                }
                <div
                  style={{border: '1px solid #CBD5E1', borderRadius: 5, backgroundColor: "#EDF1F6"}}
                  onClick={() => history.push('/club/create')} 
                  className="d-flex align-items-start justify-content-start new-club-button mx-3 py-2 px-2 mt-3"
                  id="create-club">
                  <div className="icon icon-20 me-2 new-plus"></div>
                  New Club
                </div>
              </div>
              <div className="divider"></div>
              {/* <div
                onClick={() => window.location.pathname  = `/activity`}   
                className={"menu-item flex-row d-flex items-center py-2 px-3 " + (window.location.pathname.includes('/activity') ? 'selected' : '')}>
                <div className="icon icon-20 me-2 activity"></div>
                <p className="m-0 p-0"> 
                  Activity
                </p>
              </div> */}
              <div
                onClick={() => history.push('/create-message')}  
                id="send-a-message" 
                className={"menu-item flex-row d-flex items-center py-2 px-3 " + (window.location.pathname.includes('/create-message') ? 'selected' : '')}>
                <div className="icon icon-20 me-2 send-message"></div>
                <p className="m-0 p-0"> 
                  Send a Message
                </p>
              </div>
              <div
                onClick={() => history.push('/messaging/sent-items')}   
                id="messaging"
                className={"menu-item flex-row d-flex items-center py-2 px-3 " + (window.location.pathname.includes('/messaging') ? 'selected' : '')}>
                <div className="icon icon-20 me-2 new-messaging"></div>
                <p className="m-0 p-0"> 
                  Messaging
                </p>
              </div>
              <div 
                onClick={() => history.push('/reporting/management')} 
                id="reporting"
                className={"menu-item flex-row d-flex items-center py-2 px-3 " + (window.location.pathname.includes('/reporting') ? 'selected' : '')}>
                <div className="icon icon-20 me-2 new-reporting"></div>
                <p className="m-0 p-0"> 
                  Reporting
                </p>
              </div>
              {/* <div 
                onClick={() => window.location.pathname  = `/settings/not-interested-reasons`} 
                className={"menu-item flex-row d-flex items-center py-2 px-3 " + (window.location.pathname.includes('/settings') ? 'selected' : '')}>
                <div className="icon icon-20 me-2 system-settings"></div>
                <p className="m-0 p-0"> 
                  System Settings
                </p>
              </div> */}
              <div className="divider"></div>
              <div 
                onClick={() => history.push('/help')}
                className="menu-item flex-row d-flex items-center mx-3 py-1 ps-2 support"
                id="support">
                <div className="icon icon-20 me-2 support"></div>
                <p className="m-0 p-0" style={{color: "#3B82F6"}}> 
                  Support
                </p>
              </div>
              <NavLink tag={Link} className="menu-item flex-row d-flex items-center py-2 px-3" to={{ pathname: `/authentication/logout`, state: { local: true } }}>
                <div className="icon icon-20 me-2 logout"></div>
                <p className="m-0 p-0">
                  Logout
                </p>
              </NavLink>                
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center w-100 flex-column">
            <p className="text-muted" style={{fontSize: 12, textAlign: 'center'}}>
              v2.3.8
            </p>
            {state.logo.length > 0 &&
              <div className="logo simple-logo mb-3"></div>
            }
          </div>
        </div>
      }
    </div>
  );
  
}
