import { ILookup } from "./ILookup"

export class IProductSummary {
  productId: number
  clubId: number
  categoryId: number | IProductCategory
  categoryName: string
  productName: string
}

export default class IProduct extends IProductSummary {
  productCode: string
  availableFrom: Date
  availableTo: Date
  currentPrice: number
  currentCost?: number
  vatCodeId?: ILookup | number
  allowDiscount: boolean
  url: string
  description: string
}

export class IProductCategory {
  id: number
  clubId: number
  clubName: string
  categoryName: string
}

export class IProductPrice {
  id: number
  productId: number
  effectiveFrom: Date
  retailPrice: number
  costPrice?: number
  vatCodeId?: number | ILookup
}

export class IProductVariant {
  id: number
  clubId: number
  clubName: string
  productCategoryId?: number | IProductCategory
  productCategoryName: string
  variantName: string
}