import { ILookup } from "./ILookup";

export default class IMessageLayout {
  id: number;
  clubId: number | ILookup;
  clubName?: string;
  layoutName: string;
  description?: string;
  expiryDate?: Date | string;
  header?: string;
  footer?: string;
  templateCount?: number;
}