import { ILookup } from "./ILookup";
import IMessageLayout from "./IMessageLayout";

export default class IMessageTemplate
{
  id: number;
  clubId: number | ILookup;
  clubName: string;
  templateName: string;
  description: string;
  expiryDate?: Date
  template: string;
  layoutId?: number | IMessageLayout;
  layoutName?: string;
}