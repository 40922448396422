import { lazy } from 'react';

const Dashboard = lazy(() => import("./components/screens/dashboard/Dashboard"));
const ClubSetup = lazy(() => import("./components/screens/clubAdmin/ClubSetup"));
const LocationsView = lazy(() => import("./components/screens/clubAdmin/locations/View"));
const LocationsCreate = lazy(() => import("./components/screens/clubAdmin/locations/Create"));
const ClassesAndEventsCreate = lazy(() => import("./components/screens/clubAdmin/classesAndEvents/Create"));
const ClassesAndEventsView = lazy(() => import("./components/screens/clubAdmin/classesAndEvents/View"));
const DisciplinesCreate = lazy(() => import("./components/screens/clubAdmin/disciplines/Create"));
const ClubDetailsUpdate = lazy(() => import("./components/screens/clubAdmin/details/Update"));
const MemberSearch = lazy(() => import("./components/screens/clubAdmin/memberSearch/MemberSearch"));
const MemberView = lazy(() => import("./components/screens/clubAdmin/members/View"));
const MemberCreate = lazy(() => import("./components/screens/clubAdmin/members/Create"));
const MemberUpdate = lazy(() => import("./components/screens/clubAdmin/members/Update"));
const TrainingPlanIndex = lazy(() => import("./components/screens/clubAdmin/training-plan/Index"));
const TrainingPlanView = lazy(() => import("./components/screens/clubAdmin/training-plan/View"));
const TrainingPlanUpdate = lazy(() => import("./components/screens/clubAdmin/training-plan/Update"));
const TemplatesIndex = lazy(() => import("./components/screens/messaging/templates/Index"));
const TemplatesCreate = lazy(() => import("./components/screens/messaging/templates/Create"));
const TemplatesView = lazy(() => import("./components/screens/messaging/templates/View"))
const TemplatesUpdate = lazy(() => import("./components/screens/messaging/templates/Update"));
const LayoutsCreate = lazy(() => import("./components/screens/messaging/layouts/Create"));
const LayoutsIndex = lazy(() => import("./components/screens/messaging/layouts/Index"));
const CreateMessageCreate = lazy(() => import("./components/screens/messaging/create-message/Create"));
const SentItemsIndex = lazy(() => import("./components/screens/messaging/sent-items/Index"));
const ProductsIndex = lazy(() => import("./components/screens/clubAdmin/products/Index"));
const ProductCategoriesIndex = lazy(() => import("./components/screens/clubAdmin/product-categories/Index"));
const ProductVariantsIndex = lazy(() => import("./components/screens/clubAdmin/product-variants/Index"));
const DiscountsIndex = lazy(() => import("./components/screens/clubAdmin/discounts/Index"));
const DiscountsView = lazy(() => import("./components/screens/clubAdmin/discounts/View"));
const DiscountsCreate = lazy(() => import("./components/screens/clubAdmin/discounts/Create"));
const DiscountsUpdate = lazy(() => import("./components/screens/clubAdmin/discounts/Update"));
const ClubScheduleIndex = lazy(() => import("./components/screens/tasks/club-schedule/Index"));
const MemberTaskListIndex = lazy(() => import("./components/screens/tasks/member-task-list/Index"));
const MemberOrderListIndex = lazy(() => import("./components/screens/tasks/member-order/Index"));
const UserCreate = lazy(() => import("./components/screens/clubAdmin/users/Create"));
const ClassRosterIndex = lazy(() => import("./components/screens/clubAdmin/classRoster/Index"));
const GradingClassRosterIndex = lazy(() => import("./components/screens/clubAdmin/gradingRoster/Index"));
const MarketingIndex = lazy(() => import("./components/screens/clubAdmin/marketing/Index"));
const NotInterestedIndex = lazy(() => import("./components/screens/settings/notInterestedReasons/Index"));
const MemberCategoriesIndex  = lazy(() => import("./components/screens/settings/memberCategories/Index"));
const MemberCategoriesCreateOrUpdate = lazy(() => import("./components/screens/settings/memberCategories/CreateOrUpdate"));
const ClassAvailabilityIndex = lazy(() => import("./components/screens/clubAdmin/classAvailability/Index"));
const ActivityIndex = lazy(() => import("./components/screens/tasks/Tasks"));
const MessagingIndex = lazy(() => import("./components/screens/messaging/Messaging"));
const ReportingIndex = lazy(() => import("./components/screens/reporting/Reporting"));
const HelpIndex = lazy(() => import("./components/screens/help/Index"));
const HelpCategory = lazy(() => import("./components/screens/help/Category"));
const HelpSearch = lazy(() => import("./components/screens/help/Search"));

export default [
  {
    path: '/',
    name: "Home",
    component: Dashboard
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/clubadmin/:id/:tab",
    name: "Club Admin",
    component: ClubSetup,
    exact: true
  },
  {
    path: '/clubadmin/:id/details/update',
    name: "Update",
    component: ClubDetailsUpdate
  },
  {
    path: '/clubadmin/:id/locations/view/:id', 
    name: "Location", 
    component: LocationsView
  },
  {
    path: '/clubadmin/:id/locations/create', 
    name: "Create Location", 
    component: LocationsCreate
  },
  {
    path: '/clubadmin/:id/locations/edit/:locationId',
    name: "Edit Location",
    component: LocationsCreate
  },
  {
    path: '/member/search', 
    name: "Member Search", 
    component: MemberSearch
  },
  {
    path: '/clubadmin/club/member/view/:id/:slug?', 
    name: "Member", 
    component: MemberView
  },
  {
    path: '/clubadmin/:id/classes-and-events/create', 
    name: "Create", 
    component: ClassesAndEventsCreate
  },
  {
    path: '/clubadmin/:id/classes-and-events/edit/:classId',
    name: "Edit",
    component: ClassesAndEventsCreate
  },
  {
    path: '/clubadmin/:id/users/edit',
    name: "Edit",
    component: UserCreate
  },
  {
    path: '/messaging/templates/:id', 
    name: "Templates", 
    component: TemplatesIndex
  },
  {
    path: '/messaging/templates/:clubId/create/:id', 
    name: "Create", 
    component: TemplatesCreate
  },
  {
    path: '/messaging/templates/:clubId/edit/:id?', 
    name: "Update", 
    component: TemplatesUpdate
  },
  {
    path: '/messaging/templates/view/:id?', 
    name: "Templates View", 
    component: TemplatesView
  },
  {
    path: '/messaging/layouts/:clubId/create/:id', 
    name: "Create", 
    component: LayoutsCreate
  },
  {
    path: '/messaging/layouts/:id', 
    name: "Layouts", 
    component: LayoutsIndex
  },
  {
    path: '/create-message/:clubId?/:templateId?',
    name: 'Create',
    component: CreateMessageCreate
  },
  {
    path: '/messaging/sent-items/:clubId',
    name: 'Sent Items',
    component: SentItemsIndex
  },
  /*{
    path: '/account', 
    name: "My Account", 
    component: Account
  },*/
  {
    path: '/clubadmin/classes-and-events/view/:id', 
    name: "Class & Event", 
    component: ClassesAndEventsView
  },
  {
    path: '/clubadmin/:id/disciplines/create/', 
    name: "Create Discipline", 
    component: DisciplinesCreate
  },
  {
    path: '/clubadmin/:id/disciplines/edit/:disciplineId',
    name: "Edit Discipline",
    component: DisciplinesCreate
  },
  {
    path: '/clubadmin/club/member/create/:id?', 
    name: "Create", 
    component: MemberCreate
  },
  {
    path: '/clubadmin/club/member/update/:id', 
    name: "Update", 
    component: MemberUpdate
  },
  {
    path: '/clubadmin/:id/product/index', 
    name: "Products", 
    component: ProductsIndex
  },  
  {
    path: '/clubadmin/:id/product/categories', 
    name: "Product Categories", 
    component: ProductCategoriesIndex
  },
  {
    path: '/clubadmin/:id/product/variants', 
    name: "Product Variants", 
    component: ProductVariantsIndex
  },
  {
    path: '/clubadmin/discounts', 
    name: "Discounts", 
    component: DiscountsIndex
  },
  {
    path: '/clubadmin/discounts/view/:id?', 
    name: "Discount", 
    component: DiscountsView
  },
  {
    path: '/clubadmin/discounts/club/create', 
    name: "Discount Create", 
    component: DiscountsCreate
  },
  {
    path: '/clubadmin/discounts/club/update', 
    name: "Discount Update", 
    component: DiscountsUpdate
  },
  {
    path: '/tasks/club-schedule/:id', 
    name: "Club Schedule", 
    component: ClubScheduleIndex
  },
  {
    path: '/tasks/member-order/:id',
    name: "Order Processing",
    component: MemberOrderListIndex
  },  
  {
    path: '/clubadmin/classes-and-events/class-roster/:id', 
    name: "Class Roster", 
    component: ClassRosterIndex
  },
  {
    path: '/clubadmin/classes-and-events/grading-class-roster/:id', 
    name: "Grading Class Roster", 
    component: GradingClassRosterIndex
  },
  {
    path: '/tasks/member-task-list/:id', 
    name: "Member Task List", 
    component: MemberTaskListIndex
  },
  {
    path: '/training-plans/:id', 
    name: "Training Plans", 
    component: TrainingPlanIndex
  },
  {
    path: '/training-plans/:club_id/:id', 
    name: "Training Plans", 
    component: TrainingPlanView
  },
  {
    path: '/training-plan/update/:club_id/:id', 
    name: "Training Plans", 
    component: TrainingPlanUpdate
  },
  {
    path: '/training-plan/create/:club_id', 
    name: "Training Plans", 
    component: TrainingPlanUpdate
  },
  {
    path: '/marketing/:club_id', 
    name: "Marketing", 
    component: MarketingIndex
  },
  {
    path: '/settings/not-interested-reasons/:id', 
    name: "Not Interested Reasons", 
    component: NotInterestedIndex
  },
  {
    path: '/settings/member-categories/:id', 
    name: "Member Categories", 
    component: MemberCategoriesIndex
  },
  {
    path: '/settings/member-categories/edit/:club_id/:id', 
    name: "Member Categories", 
    component: MemberCategoriesCreateOrUpdate
  },
  {
    path: '/settings/member-categories/:club_id/create', 
    name: "Member Categories", 
    component: MemberCategoriesCreateOrUpdate
  },
  {
    path: '/clubadmin/classes-and-events/:club_id/class-availability/:class_id', 
    name: "Class Availability", 
    component: ClassAvailabilityIndex
  },
  {
    path: '/club/create',
    name: 'Create Club',
    component: ClubDetailsUpdate
  },
  {
    path: '/activity/:tab',
    name: 'Activity',
    component: ActivityIndex
  },
  {
    path: '/messaging/:tab',
    name: 'Messaging',
    component: MessagingIndex
  },
  {
    path: '/reporting/:tab',
    name: 'Reporting',
    component: ReportingIndex
  },
  {
    path: '/help',
    name: "SMART Support",
    component: HelpIndex
  },
  {
    path: '/help/category/:id',
    name: "SMART Support",
    component: HelpCategory
  },
  {
    path: '/help/search',
    name: 'SMART Support',
    component: HelpSearch
  }
];
