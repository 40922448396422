import React, { Component, Suspense } from 'react';
import { Route } from 'react-router';

import { Layout } from './components/layouts/Layout.tsx';
import Routes from "./Routes.ts";

import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './main.scss'
import '@progress/kendo-theme-bootstrap/dist/all.css';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import localCurrency from 'cldr-numbers-full/main/en-GB/currencies.json';
import numbers from 'cldr-numbers-full/main/en-GB/numbers.json';
import caGregorian from 'cldr-dates-full/main/en-GB/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/en-GB/dateFields.json';

import { IntlProvider, IntlService, load, LocalizationProvider } from '@progress/kendo-react-intl';

load(
  likelySubtags,
  currencyData,
  weekData,
  localCurrency,
  numbers,
  caGregorian,
  dateFields,
);


export default class App extends Component {
  static displayName = App.name;

  render () {

    window.Intercom("update");
    
    return (      
      <IntlProvider locale="en-GB">
        <Layout>
          <Suspense fallback={<div>Loading...</div>}>          
            {Routes.map((route, index) => {
              if (route.redirect) {
                var new_route = Routes.find((other_route) => other_route.path == route.redirect);
                
                return (
                  <AuthorizeRoute exact path={new_route.path} component={new_route.component} key={index}/>
                );
              }
              return (
                <AuthorizeRoute exact path={route.path} component={route.component} key={index}/>
              );
            })}
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
          </Suspense>
        </Layout>
      </IntlProvider>                
    );
  }
}
