import { process, State } from "@progress/kendo-data-query";
import { filter } from "@progress/kendo-data-query/dist/npm/transducers";
import { type } from "os";
import { json } from "stream/consumers";
import { isArray } from "util";
import authService from "../../api-authorization/AuthorizeService";
import { BookingRequest } from "../../interfaces/IBooking";
import IClassEvent, { IClass } from "../../interfaces/IClassEvent";
import { ICreateMember } from "../../interfaces/ICreateMember";
import IDataResponse from "../../interfaces/IDataResponse";
import IDiscipline from "../../interfaces/IDiscipline";
import { IGradingResult } from "../../interfaces/IGradingResult";
import { ILookup } from "../../interfaces/ILookup";
import IMember, {IMemberAttribute, IContact, IExternalPayment, IMemberAttendance, IMemberCharge, IMemberClass, IMemberDiscipline, IMemberLink, IMemberMembership, IMemberNote, IMemberPayment, IMemberProductCharge, IMemberSubscription, IMemberTag, IMemberTransaction, IPerson, ITag } from "../../interfaces/IMember";
import { IMemberSearch } from "../../interfaces/IMemberSearch";
import INotInterested from "../../interfaces/INotInterested";
import IPaymentRequest from "../../interfaces/IPaymentRequest";
import { IProductSummary } from "../../interfaces/IProduct";
import IRenewal from "../../interfaces/IRenewal";
import IStandardResponse, { IAmountResponse, ITransactionResponse, IUrlResponse } from '../../interfaces/IStandardResponse';
import { Renew } from "../../screens/clubAdmin/members/modal/renew/Renew";
import BaseService from "../BaseService";


export class MemberService extends BaseService {
  	                                 
  constructor() {
    super();
  }
  
  async getMemberDisciplines (memberId: number, dataState) {
    const response = this.getODataApiCall<IDataResponse<IDiscipline[]>>(`/api/member/GetDisciplines/${memberId}`, dataState);

    response.then((d : any) => {
      d.data.forEach((item : any) => {
        if (item.lastGrading) { item.lastGrading = new Date(item.lastGrading) }      
        if (item.studiedSince) { item.studiedSince = new Date(item.studiedSince) }      
      });
    }); 

    return response;
    
  }

  async getAttributes (memberId: number, dataState: State) {
    const response = this.getODataApiCall<IDataResponse<IMemberAttribute[]>>(`api/member/GetAttributes/${memberId}`, dataState);
    response.then((d) => {
      d.data.forEach((item: any) => {
        if (item.created) { item.created = new Date(item.created) }
      });
    });
    return response;
  }

  async saveAttribute (memberId: number, payload: IMemberAttribute): Promise<IStandardResponse> {

    let body = {
      memberAttributeId: payload.id,
      attributeId: 0,
      memberId: memberId,
      value: payload.value
    };
    body.attributeId = (typeof payload.attributeId === "object") ? payload.attributeId.id : payload.attributeId;

    const response = this.postApiCall<IStandardResponse>(`api/member/SaveAttribute/${memberId}`, body);
    return response;
  }

  async deleteAttribute (memberAttributeId: number): Promise<IStandardResponse> {
    const response = this.postApiCall<IStandardResponse>(`api/member/DeleteAttribute/${memberAttributeId}`, {});
    return response;
  }

  async getAttendances (memberId: number, dataState: State) {
    
    
    var filterData = {
      MemberId: memberId
    };

    const response = this.postODataApiCall<IDataResponse<IMemberAttendance[]>>(`api/member/GetAttendances/${memberId}`, filterData, dataState);
    response.then((d) => {
      d.data.forEach((item : any) => {
        if (item.attendedDate) { item.attendedDate = new Date(item.attendedDate) }      
      });             
    });   
        
    return response;
  }

  async getTransactions(memberId: number, dataState: State) {

    const response = this.getODataApiCall<IDataResponse<IMemberTransaction[]>>(`api/member/GetTransactions/${memberId}`, dataState);
    response.then((d) => {
      d.data.forEach((item: IMemberTransaction) => {

        if (item.transactionDate) { item.transactionDate = new Date(item.transactionDate) }
        if (item.paymentDate) { item.paymentDate = new Date(item.paymentDate) }
      });
    });
    return response;
  }

  async getPayments(memberId: number, transactionId: number | null, dataState: State) {

    const response = this.getODataApiCall<IDataResponse<IMemberPayment[]>>(`api/member/GetPayments/${memberId}?transactionId=${transactionId}`, dataState);
    response.then((d) => {
      d.data.forEach((item: IMemberPayment) => {

        if (item.paymentDate) { item.paymentDate = new Date(item.paymentDate) }
      });
    });
    return response;
  }

  async getExternalPayments(memberId: number, dataState: State) {
    const response = this.getODataApiCall<IDataResponse<IExternalPayment[]>>(`api/member/GetExternalPayments/${memberId}`, dataState);
    response.then((d) => {
      d.data.forEach((item: IExternalPayment) => {

        if (item.chargeDate) { item.chargeDate = new Date(item.chargeDate) }
        if (item.createdAt) { item.createdAt = new Date(item.createdAt) }
      });
    });
    return response;
  }

  async getCreditBalance(memberId: number) {

    const response = this.getApiCall<IAmountResponse>(`/api/member/GetCreditBalance/${memberId}`);
    return response;
  }

  async getClasses (club_id: number, class_date?: Date) {
    

    if(class_date) {      
      class_date.setMinutes(class_date.getMinutes() - class_date.getTimezoneOffset());
    }
    
    var classFilter = {
      clubIds: [club_id],
      classDate: class_date
    };

    if(!class_date) {
      classFilter.classDate = undefined;
    }

    const response = this.postApiCall<IClass[]>(`api/club/GetClassList`, classFilter);      

    response.then((d) => {

      d.forEach((v) => {

        if(v.startDate) { v.startDate = new Date(v.startDate) }
        if(v.endDate) { v.endDate = new Date(v.endDate) }
        if(v.selectedDate) { v.selectedDate = new Date(v.selectedDate) }

        v.description = `${v.className} [${v.clubCode}]`;
      });
    });
    
    return response;
  }

  async getMemberClasses (member_id: number, dataState: State) {
    const response = this.getODataApiCall<IDataResponse<IMemberClass[]>>(`api/member/GetClasses/${member_id}`, dataState);
    
    response.then((d) => {
      d.data.forEach((v) => {

        if(v.dateAdded) { v.dateAdded = new Date(v.dateAdded)}
        if(v.lastGrading) { v.lastGrading = new Date(v.lastGrading)}
        if(v.lastAttendance) { v.lastAttendance = new Date(v.lastAttendance)}        
      });
    });
    
    return response;
  }

  async getAvailableEvents(memberId: number, searchTerm: string, type?: number) {

    const response = this.getApiCall<IClass[]>(`api/member/GetAvailableEvents/${memberId}?type=${type}&searchterm=${searchTerm}`);
    return response;
  }

  async getAvailableProducts(memberId: number, searchTerm: string) {
    const response = this.getApiCall<IProductSummary[]>(`/api/member/GetAvailableProducts/${memberId}?searchterm=${searchTerm}`);
    return response;
  }

  async getProductPrice(memberId: number, productId: number) {
    
    const response = this.getApiCall<IAmountResponse>(`/api/member/GetProductPrice/${memberId}?productId=${productId}`);
    return response;
  }

  async deleteMemberClass (member_class_id: number) {
    const response = this.postApiCall<IStandardResponse>(`api/member/RemoveClass/${member_class_id}`, {});  
    return response;
  }

  async saveClass (payload : any, member_id: number) {
    const response = this.postApiCall<IStandardResponse>(`/api/member/AddClass/${member_id}`, payload);
    return response;
  }

  async saveMemberAttendance (payload: any, id : number) {
    
    this.fixDate(payload.attendedDate);

    const response = this.postApiCall<IStandardResponse>(`/api/member/SaveAttendance/${id}`, payload);
    return response; 
  }

  setGradingCandidate(memberClassId: number, isCandidate: boolean) {
    
    const response = this.postApiCall<IStandardResponse>(`/api/member/SetGradingCandidate/${memberClassId}?isCandidate=${isCandidate}`, {});
    return response;
  }

  saveGradingResult(result: IGradingResult){
    
    const response = this.postApiCall<IStandardResponse>(`/api/member/SaveGradingResult/${result.memberId}`, result);
    return response;
  }
  
  async savePayment(payment: IMemberPayment): Promise<ITransactionResponse> {

    let paymentMethodId = payment.paymentMethodId;
    if (typeof (payment.paymentMethodId) === "object") { payment.paymentMethodId = payment.paymentMethodId.id; }

    if (payment.paymentDate) {
      payment.paymentDate.setMinutes(payment.paymentDate.getMinutes() - payment.paymentDate.getTimezoneOffset());
    }

    let response = await this.postApiCall<ITransactionResponse>(`/api/member/SavePayment/${payment.memberId}`, payment);

    payment.paymentMethodId = paymentMethodId;

    return response;
  }

  async saveTransaction(t: IMemberCharge | IMemberProductCharge) {

    let categoryDdl = t.categoryId;
    if (typeof (t.categoryId) === "object") t.categoryId = t.categoryId.id;

    let productId: number = 0;
    let variantId: number = 0;

    if (typeof (t.productId) === "object" && "productId" in t.productId) { productId = t.productId.productId; }
    else if (typeof t.productId === "object") { productId = t.productId.classId; } 
    else if (typeof t.productId == "number") { productId = t.productId; }

    if (typeof (t.variantId) === "object") { variantId = t.variantId.id; }
    else if(t.variantId) { variantId = t.variantId; }
   
    let paymentMethodDdl = t.paymentMethodId;
    if (typeof t.paymentMethodId === "object" && t.paymentMethodId) { t.paymentMethodId = t.paymentMethodId.id; }

    // During BST we end up getting the day before when JSON.Stringify is called.  Fixed below.    
    this.fixDate(t.transactionDate);
    
    let payload: any = {
      "memberId": t.memberId,
      "transactionId": t.transactionId??0,
      "categoryId": t.categoryId,
      "eventId": 0,
      "productId": 0,
      "variantId": 0,
      "amount": t.amount,
      "tax": 0,
      "transactionDate": t.transactionDate.toISOString().substr(0, 10),
      "paymentMethodId": t.paymentMethodId,      
      "description": t.description,
      "savedMethod": t.savedMethod
    };

    if (t.mode == "product") {
      payload.productId = productId;
      payload.variantId = variantId;
    }

    if (t.mode == "event") {
      payload.eventId = productId;
    }

    if (t.paymentDate) {
      t.paymentDate.setMinutes(t.paymentDate.getMinutes() - t.paymentDate.getTimezoneOffset());
      payload.paymentDate = t.paymentDate.toISOString().substr(0, 10);
    }  

    const response = this.postApiCall<ITransactionResponse>(`/api/member/SaveTransaction/${t.memberId}`, payload);

    t.categoryId = categoryDdl;
    t.paymentMethodId = paymentMethodDdl;

    return response;
  }

  async saveNote(note: IMemberNote) : Promise<IStandardResponse> {
    
    let noteCategoryId = note.categoryId;
    if(typeof noteCategoryId == "object") { noteCategoryId = noteCategoryId.id; }

    note.reminder?.setMinutes(note.reminder.getMinutes() - note.reminder.getTimezoneOffset());

    let body = {
      memberNoteId: note.id,
      memberId: note.memberId,
      noteCategoryId: noteCategoryId,
      subject: note.subject,
      note: note.note,
      reminder: note.reminder,
      completed: note.completed,
      isPinned: note.isPinned,
      sendSms: note.hasSms,
      sendEmail: note.hasEmail
    };

    let response = await this.postApiCall<IStandardResponse>(`/api/member/SaveNote/${note.memberId}`, body);

    note.categoryId = noteCategoryId;

    return response;    
  }

  async getMemberDetails(memberId: number): Promise<IMember> {
    const response = this.getApiCall<IMember>(`api/member/GetDetails/${memberId}`);
    response.then((d) => {
      if (d.dob) { 
        d.dob = new Date(d.dob) 
        d.dob.setHours(0);      // Some DOB fields are set to 11pm which causes issues.
      }      
    });    
    return response;
  }

  async getPinnedNotes(memberId: number): Promise<IMemberNote[]> {

    const response = await this.getApiCall<IMemberNote[]>(`api/member/GetPinnedNotes/${memberId}`).then((r) => {

      r.forEach((item) => {

        if(item.noteDate) { item.noteDate = new Date(item.noteDate); }
      });
      return r;
    });
    return response;
  }

  async getNotes(memberId: number, dataState: State) {
    
    const response = await this.getODataApiCall<IDataResponse<IMemberNote[]>>(`api/member/GetNotes/${memberId}`, dataState).then((r) => {

      r.data.forEach((i) => {

        if(i.noteDate) { i.noteDate = new Date(i.noteDate); }
        if(i.reminder) { i.reminder = new Date(i.reminder); }
      });
      return r;
    });
    
    
    return response;
  }

  async getMembership(memberId: number): Promise<IMemberMembership> {

    const response = this.getApiCall<IMemberMembership>(`api/member/getMembership/${memberId}`);

    response.then((d) => {
      if (d.memberSince) { d.memberSince = new Date(d.memberSince); }
      if (d.memberUntil) { d.memberUntil = new Date(d.memberUntil); }
      if (d.nextRenewal) { d.nextRenewal = new Date(d.nextRenewal); }      
      if (d.nextPayment) { d.nextPayment = new Date(d.nextPayment); }
      if (d.trialStart) { d.trialStart = new Date(d.trialStart); }
    });    
    return response;
  }

  async getSubscriptions(memberId: number): Promise<IMemberSubscription[]> {

    const response = this.getApiCall<IMemberSubscription[]>(`api/member/getSubscriptions/${memberId}`);

    response.then((d) => {
      for (var i = 0; i < d.length; i++) {
        if (d[i].createdAt) { d[i].createdAt = new Date(d[i].createdAt); }
        if (d[i].startDate) { d[i].startDate = new Date(d[i].startDate); }
        if (d[i].nextPayment) { d[i].nextPayment = new Date(d[i].nextPayment); }
      }
    });
    return response;
  }


  getMandateRedirectUrl(membershipId: number): Promise<IUrlResponse> {

    const response = this.getApiCall<IUrlResponse>(`api/GoCardless/NewCustomer/${membershipId}`);
    return response;
  }

  sendMandateInvite(memberId: number): Promise<IStandardResponse> {

    const response = this.getApiCall<IStandardResponse>(`/api/member/SendDDInvite/${memberId}`);
    return response;
  }

  async renewMembership(renewal: IRenewal) {

    let token = await authService.getAccessToken();

    let methodDdl = renewal.paymentMethod;
    if (typeof (renewal.paymentMethod) === "object") renewal.paymentMethod = renewal.paymentMethod.id;

    this.fixDate(renewal.nextRenewalDate);
    this.fixDate(renewal.paymentDate);  

    const response = await fetch(`/api/member/renewmembership/${renewal.memberId}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST',
      body: JSON.stringify(renewal)
    });

    renewal.paymentMethod = methodDdl;

    return response; 
  }

  async lapseMembership(lapseReason: INotInterested) {
    let token = await authService.getAccessToken();

    let reasonDdl = lapseReason.reason;
    if (typeof (lapseReason.reason) === "object") lapseReason.reason = lapseReason.reason.id;

    const response = await fetch(`/api/member/lapseMembership/${lapseReason.memberId}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST',
      body: JSON.stringify(lapseReason)
    });

    lapseReason.reason = reasonDdl;

    return response; 
  }

  async saveMembership(membership: IMemberMembership) {

    let token = await authService.getAccessToken();

    let clubDdl = membership.clubId;
    if (typeof (membership.clubId) === "object") membership.clubId = membership.clubId.id;

    let categoryDdl = membership.categoryId;
    if (typeof (membership.categoryId) === "object") membership.categoryId = membership.categoryId.id;

    let tpDdl = membership.trainingPlanId; 
    if (tpDdl && typeof (membership.trainingPlanId) === "object") membership.trainingPlanId = membership.trainingPlanId.id;

    let freqDdl = membership.subFrequencyId;
    if (freqDdl && typeof (membership.subFrequencyId) === "object") membership.subFrequencyId = membership.subFrequencyId.id;

    let pmDdl = membership.paymentMethodId;
    if (pmDdl && typeof (membership.paymentMethodId) === "object") membership.paymentMethodId = membership.paymentMethodId.id;

    let msDdl = membership.marketingSourceId;
    if (msDdl && typeof (membership.marketingSourceId) === "object") membership.marketingSourceId = membership.marketingSourceId.id;

    // During BST we end up getting the day before when JSON.Stringify is called.  Fixed below.

    this.fixDate(membership.memberSince)
    this.fixDate(membership.nextRenewal);
    this.fixDate(membership.memberUntil);
    this.fixDate(membership.nextPayment);
    this.fixDate(membership.trialStart);
    
    const response = await fetch(`/api/member/saveMembership/${membership.memberId}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST',
      body: JSON.stringify(membership)
    });

    membership.clubId = clubDdl;
    membership.categoryId = categoryDdl;
    membership.trainingPlanId = tpDdl;
    membership.subFrequencyId = freqDdl;
    membership.paymentMethodId = pmDdl;
    membership.marketingSourceId = msDdl;

    return response; 
  }

  async savePerson(person: IPerson) {

    let token = await authService.getAccessToken();

    let genderDdl = person.gender;
    if (typeof person.gender == 'object') { person.gender = (person.gender?.value) ? person.gender.value : '-'; }

    if (person.dob) {
      person.dob.setMinutes(person.dob.getMinutes() - person.dob.getTimezoneOffset());
    }

    const response = await fetch(`/api/member/SavePerson/${person.personId}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST',
      body: JSON.stringify(person)
    });

    person.gender = genderDdl;

    return response; 
  }

  async saveContact(contact: IContact) {

    if(typeof contact.gender == 'object') { contact.gender = contact.gender.value ? contact.gender.value : ''; }

    const response = this.postApiCall<IStandardResponse>(`/api/member/SaveContact/${contact.memberId}`, contact);
    return response;
  }

  async saveMemberLink(member: IMemberLink) {
    const response = this.postApiCall<IStandardResponse>(`/api/member/SaveMemberLink/${member.memberId}`, member);
    return response;
  }
  

  async saveDiscipline(payload: IMemberDiscipline) {
    let token = await authService.getAccessToken();

    this.fixDate(payload.lastGrading);
    this.fixDate(payload.studiedSince);

    if (typeof payload.disciplineId === "object") { payload.disciplineId = payload.disciplineId.disciplineId; }
    if (typeof payload.currentGradeId == "object") { payload.currentGradeId = payload.currentGradeId.gradeId; }


    const response = await fetch(`/api/member/SaveDiscipline/${payload.memberId}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST',
      body: JSON.stringify(payload)
    });

    return response; 
  }

  async deleteDiscipline(memberId: number, memberDisciplineId: number) {
    const response = this.postApiCall<IStandardResponse>(`/api/member/RemoveDiscipline/${memberId}`,
      { memberDisciplineId: memberDisciplineId }
    );
    return response;
  }

  async deleteTransaction(transactionId: number) {
    const response = this.postApiCall<IStandardResponse>(`/api/member/DeleteTransaction/${transactionId}`, {});
    return response;
  }

  async deletePayment(paymentId: number) {
    const response = this.postStandardApiCall(`/api/member/DeletePayment/${paymentId}`, {});
    return response;
  }

  async deleteNote(noteId: number) {
    const response = this.postApiCall<IStandardResponse>(`/api/member/DeleteNote/${noteId}`, {});
    return response;
  }  

  async deleteLink(clubMemberLinkId: number) {
    const response = this.postApiCall<IStandardResponse>(`/api/member/DeleteLink/${clubMemberLinkId}`, {});
    return response;
  }
  
  async deleteContact(memberId: number, personId: number) {
    
    try {
      const response = this.postApiCall<IStandardResponse>(`/api/member/DeleteContact/${memberId}?personId=${personId}`, {});
      return response;
    } catch(err) {
            
      let err2 = err as Error;
      const response: IStandardResponse = {
        isSuccess: false,
        message: err2.message
      };
      return response;
    }    
  }

  async deleteMember(memberId: number) {
    //const response = this.postApiCall<IStandardResponse>(`/api/member/delete/${memberId}`, {});
    const response = this.postStandardApiCall(`/api/member/DeleteMembership/${memberId}`, {});
    return response;
  }
  

  async cancelExternalPayment(memberId: number, paymentId: number, providerId: string) {
    const response = this.postApiCall<IStandardResponse>(`/api/member/CancelExternalPayment/${memberId}`, {
      paymentId: paymentId,
      providerId: providerId
    });
    return response;
  }

  async takePayment(pmt: IPaymentRequest) {

    const response = this.postApiCall<ITransactionResponse>(`/api/member/TakePayment/${pmt.transactionId}`, pmt);
    return response; 
  }

  async toggleIssueStatus(memberId: number) {

    let token = await authService.getAccessToken();

    const response = await fetch(`/api/member/ToggleIssueStatus/${memberId}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'GET'
    });

    return response; 
  }


  async cancelDirectDebit(memberId: number) {

    let token = await authService.getAccessToken();

    const response = await fetch(`/api/GoCardless/CancelMandate/${memberId}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'GET'
    });

    return response; 
  }

  async changePlan(d: IMemberMembership) {
    let token = await authService.getAccessToken();

    let planId = (typeof (d.trainingPlanId) === "object") ? d.trainingPlanId.id : d.trainingPlanId;
    let freqId = (typeof (d.subFrequencyId) === "object") ? d.subFrequencyId.id : d.subFrequencyId;
    let methodId = (typeof (d.paymentMethodId) === "object") ? d.paymentMethodId.id : d.paymentMethodId;

    // During BST we end up getting the day before when JSON.Stringify is called.  Fixed below.
    d.nextPayment?.setMinutes(d.nextPayment.getMinutes() - d.nextPayment.getTimezoneOffset());    

    let data = {
      memberId: d.memberId,
      clubMemberId: d.membershipId,
      trainingPlanId: planId,
      subFrequencyId: freqId,
      subPrice: d.subPrice,
      paymentMethodId: methodId,
      nextPayment: d.nextPayment
    }

    const response = await fetch(`/api/member/ChangePlan/${d.memberId}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST',
      body: JSON.stringify(data)
    });

    return response; 
  }

  async searchMembers(filterCriteria: IMemberSearch, dataState: State) {

    if (isArray(filterCriteria.categories)) {
      filterCriteria.categories = filterCriteria.categories.map((v: number | ILookup) => {
        let val = (typeof v === 'object') ? v.id : v;
        return val;
      });
    }

    if (isArray(filterCriteria.clubs)) {
      filterCriteria.clubs = filterCriteria.clubs.map((v) => {
        return (typeof v === 'object') ? v.id : v;
      });
    }

    if (isArray(filterCriteria.statuses)) {
      filterCriteria.statuses = filterCriteria.statuses.map((v) => {
        return (typeof v === 'object') ? v.id : v;
      });
    }

    if (typeof filterCriteria.disciplineId === 'object') { filterCriteria.disciplineId = filterCriteria.disciplineId.disciplineId; }
    if (typeof filterCriteria.gradeFrom === 'object') { filterCriteria.gradeFrom = filterCriteria.gradeFrom.gradeId; }
    if (typeof filterCriteria.gradeTo === 'object') { filterCriteria.gradeTo = filterCriteria.gradeTo.gradeId; }
    
    const response = await this.postODataApiCall<IDataResponse<IMember[]>>(`api/member/search/`, filterCriteria, dataState);
    return response;
  }

  basicSearch(value: string, clubId: number): Promise<ILookup[]> {

    const response = this.getApiCall<ILookup[]>(`/api/member/basicSearch?filter=${value}&clubId=${clubId}`);
    return response;
  }

  classSearch(value: string, classId: number, classDate: Date): Promise<ILookup[]> {

    const classDateStn = classDate.toISOString();
    const response = this.getApiCall<ILookup[]>(`/api/member/classSearch?filter=${value}&classId=${classId}&classDate=${classDateStn}`);
    return response;
  }

  async addTags(member_ids: number[], tags: ITag[]) {

    let token = await authService.getAccessToken();

    let data = {
      members: member_ids,
      tags: tags
    }

    const response = await fetch(`/api/member/AddTags/`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST',
      body: JSON.stringify(data)
    });

    return response; 
  }

  async removeTags(member_ids: number[], tags: ITag[]) {

    let token = await authService.getAccessToken();

    let data = {
      members: member_ids,
      tags: tags
    }

    const response = await fetch(`/api/member/RemoveTags/`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST',
      body: JSON.stringify(data)
    });

    return response; 
  }

  async getMemberTags(memberId: number, dataState: State) {

    const response = await this.getODataApiCall<IDataResponse<IMemberTag[]>>(`/api/member/GetTags/${memberId}`, dataState);

    for (var i = 0; i < response.data.length; i++) {

      var tmpDate = response.data[i].dateAdded;
      if (tmpDate != null) response.data[i].dateAdded = new Date(tmpDate);
    }

    return response;
  }

  async createMember(member: ICreateMember) {

    let token = await authService.getAccessToken();

    interface IBody
    {
      person?: IPerson
      secondContact?: IPerson
      membership?: IMemberMembership 
      gradeId?: number
      classId?: number | IClass
      notes?: string
    };

    let body: IBody = {
      person: member.person,
      secondContact: member.secondContact,
      membership: member.membership,
      gradeId: member.grade?.gradeId,
      classId: member.class,
      notes: member.notes
    };

    if (typeof body.person?.gender == 'object') { body.person.gender = body.person.gender.value ? body.person.gender.value : '-'; }
    if (typeof body.secondContact?.gender == 'object') { body.secondContact.gender = body.secondContact.gender.value ? body.secondContact.gender.value : '-'; }
    if (typeof body.membership?.categoryId == 'object') { body.membership.categoryId = body.membership.categoryId.id; }
    if (typeof body.membership?.clubId == 'object') { body.membership.clubId = body.membership.clubId.id; }
    if (typeof body.membership?.marketingSourceId == 'object') { body.membership.marketingSourceId = body.membership.marketingSourceId.id; }
    if (typeof body.classId == 'object') { body.classId = body.classId?.classId; }
    
    // During BST we end up getting the day before when JSON.Stringify is called.  Fixed below.
    this.fixDate(body.membership?.memberSince)
    this.fixDate(body.membership?.nextRenewal);
    this.fixDate(body.membership?.memberUntil);
    this.fixDate(body.membership?.nextPayment);
    this.fixDate(body.membership?.trialStart);
    this.fixDate(body.person?.dob);
    this.fixDate(body.secondContact?.dob);
    
    const response = await fetch(`/api/member/Create/`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST',
      body: JSON.stringify(body)
    });

    return response; 
  }

  async activate(memberId: number, bookingId: number): Promise<IStandardResponse> {
    
    const body = {
      memberId: memberId,
      bookingId: bookingId
    };
    const response = await this.postApiCall<IStandardResponse>("/api/notification/scheduler/activation", body);

    return response;
  }
  
  async saveBooking(booking: BookingRequest): Promise<IStandardResponse> {
		
    if(typeof booking.bookingDate === 'object') {
      booking.bookingDate.setMinutes(booking.bookingDate.getMinutes() - booking.bookingDate.getTimezoneOffset());
    }
    
    const response = await this.postApiCall<IStandardResponse>(`/api/member/SaveBooking/${booking.memberId}`, booking);
    return response;
	}

  async cancelBooking(booking: BookingRequest): Promise<IStandardResponse> {
    const response = await this.postApiCall<IStandardResponse>(`/api/member/CancelBooking/${booking.memberId}`, booking);
    return response;
  }

  async getMemberContacts (id: number, state: State): Promise<IDataResponse<IContact[]>> {
    
    let data = await this.getODataApiCall<IDataResponse<IContact[]>>(`/api/member/GetContacts/${id}`, state);

    data.data.forEach((v) => {

      if(v.dob) { v.dob = new Date(v.dob); }
      if(!v.phoneDay) { v.phoneDay = ""; }
      if(!v.phoneEve) { v.phoneEve = ""; }
      if(!v.phoneMob) { v.phoneMob = ""; }

    });
    return data;
  }

  async getMemberLinks(id: number, state: State): Promise<IDataResponse<IMemberLink[]>> {

    let data = await this.getODataApiCall<IDataResponse<IMemberLink[]>>(`/api/member/GetLinkedMembers/${id}`, state);

    data.data.forEach((v) => {
      v.linkedSince = new Date(v.linkedSince);
    });
    return data;
  }

  async cancelSubscription(memberId: number, subscriptionId): Promise<IStandardResponse> {

    const body = {
      memberId: memberId,
      providerId: subscriptionId
    };

    const response = this.postApiCall<IStandardResponse>(`/api/member/CancelSubscription/${memberId}`, body);
    return response;
  }

}

const memberService = new MemberService();
export default memberService;