export default class IUser {
  userId: string;
  clubUserId: number;
  clubId: number;
  personId: number;
  userSince: Date | null;
  userUntil: Date | null;
  dateAdded: Date;
  isAdmin: boolean;
  lastLogin: Date | null;
  userName: string;
  firstName: string;
  lastName: string;
  fullName: string;
  lastActivity: Date | null;
  email: string;
  phoneMobile: string;
}