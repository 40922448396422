import React, { Component } from 'react';
import { SideMenu } from './sidemenu/SideMenu';
import { useLocation } from 'react-router-dom'
import authService from '../api-authorization/AuthorizeService';

interface IProps {
  children: React.ReactNode;
}

export class Layout extends Component<IProps> {

  componentDidMount() {

    authService.getUser().then((user) => {

      if (user) {
        
        let user_name = user.name as string;
        user_name = user_name[0].toUpperCase() + user_name.substring(1);      

        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "fq11mbnk",
          name: user_name, // Full name
          user_id: user.sub, // Email address
          created_at: user.created_at, // Signup date as a Unix timestamp
          user_hash: user.user_hash,
          email: user.email,
        });
      }
    });    
  }

  render() {
    return (
      <div className="app">
        {window.location.pathname !== '/authentication/login' && !window.location.pathname.startsWith('/authentication/login-callback') &&
          <SideMenu></SideMenu>
        }
        <div className="app-content">
          {/* <Container> */}
            {this.props.children}
          {/* </Container> */}
        </div>
      </div>
    );
  }
}
