import IDiscipline from "./IDiscipline";
import ILocation from "./ILocation";
import { ILookup } from "./ILookup";
import WeeklyPattern from './IWeeklyPattern';

export default class IClassEvent {
  name: string
  location: string
  type: string
}

export class IClass {
  classId: number = 0;
  className: string
  clubCode: string;
  clubName: string;
  clubId: number
  selectedDate: Date | undefined;
  startTime: string;
  finishTime: string;
  location: string;
  locationId: number | ILocation;
  classType: string;
  classTypeId: number | ILookup;
  capacity: number;
  reservations: number;
  startDate: Date | undefined;
  endDate: Date | undefined;
  primaryDisciplineId: number | IDiscipline;  
  cost: number;
  allowDiscount: boolean;
  chargeOnAttendance: boolean;
  webBookable: boolean;
  propsectLimit: number;
  weeklyTimetable: WeeklyPattern;
  description?: string;
}


